<template>
  <Card>
    <Heading
      v-t="'returns.download'"
      level="2" />
    <Paragraph v-t="'returns.download_info'" />
    <ReturnsDownloadForm />
  </Card>
</template>

<script>
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import ReturnsDownloadForm from '@/components/returns/ReturnsDownloadForm';

export default {
  name: 'ReturnsDownload',
  components: {
    Card,
    Heading,
    Paragraph,
    ReturnsDownloadForm,
  },
};
</script>
