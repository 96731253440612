<template>
  <form
    class="tw-flex tw-pt-2"
    @submit.prevent="downloadLabel">
    <BFormInput
      v-model.trim="email"
      required
      type="email"
      :placeholder="$t('form.email')"
      class="rounded-pill tw-mr-2" />

    <StatusButton
      v-test="{
        e2e: 'download',
        button: isPrinterless ? 'show' : 'download',
      }"
      :loading="downloadingLabel"
      :success="success"
      @click.prevent="downloadLabel">
      {{ $t(isPrinterless ? 'form.submit' : 'download') }}
    </StatusButton>
  </form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import MyParcelApi from '@/services/MyParcelApi';
import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import StatusButton from '@/components/StatusButton';
import { getUrl } from '@/helpers/platform';
import { hasEmail } from '@/mixins/hasEmail';
import { isDemo } from '@/services/functions/isDemo';

export default {
  name: 'ReturnsDownloadForm',
  components: { StatusButton },

  mixins: [hasEmail],

  props: {
    isPrinterless: {
      type: Boolean,
    },
  },

  emits: ['done'],

  data() {
    return {
      downloadingLabel: false,
      success: null,
    };
  },

  computed: {
    ...mapGetters(['fields']),
    ...mapState(STORE_MODULE_RETURNS, ['returnData']),

    /**
     * @returns {string}
     */
    hash() {
      return this.$route.params.hash;
    },

    /**
     * @returns {string}
     */
    downloadLink() {
      if (isDemo()) {
        return '';
      }

      return `${getUrl('api')}${MyParcelApi.urls.return_shipment_labels}/${this.hash}`;
    },
  },

  methods: {
    /**
     * Download the label and email it to the filled in email address.
     */
    downloadLabel() {
      if (isDemo()) {
        this.success = true;

        return;
      }

      this.downloadingLabel = true;
      // Wait for the email to be sent before printing the label.
      MyParcelApi.emailReturnLabel({
        email: this.email,
        hash: this.hash,
      }).then(() => {
        this.success = true;

        if (!this.isPrinterless) {
          // Navigate to the download url. This is not done in a new tab to prevent popup blockers from killing it.
          window.location.assign(this.downloadLink);
        }
      }).catch(() => {
        this.success = false;
      }).finally(() => {
        this.downloadingLabel = false;
        this.$emit('done');
      });
    },
  },
};
</script>
