<template>
  <Grid>
    <ReturnsPrinterless
      v-if="returnData.has_printerless_return"
      key="returns__printerless" />
    <ReturnsDownload
      v-else
      key="returns__download" />

    <ReturnsInstructions key="returns__instructions" />
  </Grid>
</template>

<script>
import Grid from '@/components/Grid';
import ReturnsDownload from '@/components/returns/ReturnsDownload';
import ReturnsInstructions from '@/components/returns/ReturnsInstructions';
import ReturnsPrinterless from '@/components/returns/ReturnsPrinterless';
import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import { mapState } from 'vuex';

export default {
  name: 'ReturnsLastStep',
  components: {
    Grid,
    ReturnsPrinterless,
    ReturnsInstructions,
    ReturnsDownload,
  },

  computed: {
    ...mapState(STORE_MODULE_RETURNS, ['returnData']),
  },
};
</script>
