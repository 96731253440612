var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',[_c('Heading',{directives:[{name:"t",rawName:"v-t",value:('returns.download_printerless'),expression:"'returns.download_printerless'"}],attrs:{"level":"2"}}),_c('Paragraph',{directives:[{name:"t",rawName:"v-t",value:({
      path: 'returns.download_printerless_info',
      args: _vm.servicePointTranslation,
    }),expression:"{\n      path: 'returns.download_printerless_info',\n      args: servicePointTranslation,\n    }"}]}),_c('SmoothReflow',[(_vm.returnData.email_label)?_c('Paragraph',{directives:[{name:"t",rawName:"v-t",value:({
        path: 'returns.download_printerless_info_after',
        args: {
          email: _vm.returnData.email_label,
        },
      }),expression:"{\n        path: 'returns.download_printerless_info_after',\n        args: {\n          email: returnData.email_label,\n        },\n      }"}]}):_c('Paragraph',{directives:[{name:"t",rawName:"v-t",value:('returns.download_printerless_info_before'),expression:"'returns.download_printerless_info_before'"}]}),_c('Transition',{attrs:{"name":"fade"}},[(!_vm.returnData.email_label)?_c('ReturnsDownloadForm',{attrs:{"is-printerless":""}}):_vm._e()],1),_c('div',{staticClass:"tw--mx-5 tw-pt-4"},[_c('div',{staticClass:"tw-max-w-md tw-mx-auto tw-w-full"},[_c('CImage',{directives:[{name:"test",rawName:"v-test",value:('returns-printerless-label'),expression:"'returns-printerless-label'"}],staticClass:"tw-h-56",attrs:{"src":_vm.printerlessLabelSrc,"alt":"return_label"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('PrinterlessReturnBarcodeSkeleton')]},proxy:true}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }