<template>
  <Card>
    <Heading
      v-t="'returns.instructions_header'"
      level="2" />

    <div
      v-for="(instruction, index) in instructions"
      :key="`${index}_${instruction.title}`"
      class="md:tw-pr-5 tw-border tw-border-gray-200 tw-border-solid tw-flex tw-mt-3 tw-pr-3 tw-py-3 tw-rounded-xl">
      <div class="tw-flex tw-w-1/12">
        <Heading
          level="3"
          inline
          class="tw-m-auto tw-text-primary-500"
          v-text="index + 1" />
      </div>

      <div class="tw-w-11/12">
        <Heading
          v-t="instruction.title"
          level="4" />

        <Paragraph v-t="instruction.body" />

        <component
          :is="instruction.content.component"
          v-bind="instruction.content.attributes"
          v-if="instruction.content" />
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import Paragraph from '@/components/Paragraph';
import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import { mapState } from 'vuex';

export default {
  name: 'ReturnsInstructions',
  components: { Card, Paragraph, Heading },
  computed: {
    ...mapState(STORE_MODULE_RETURNS, ['returnData']),

    /**
     * @returns {{service_point: string}}
     */
    servicePointTranslation() {
      return {
        service_point: this.$t(`carriers.${this.carrierName}.service_point`),
      };
    },

    /**
     * @returns {string|undefined}
     */
    carrierName() {
      if (!this.returnData) {
        return;
      }

      return this.returnData.carrier.name;
    },

    /**
     * @returns {Object[]}
     */
    instructions() {
      const prefix = this.returnData.has_printerless_return ? 'printerless_' : '';

      return [
        {
          title: `returns.instructions_${prefix}1_title`,
          body: `returns.instructions_${prefix}1`,
        },
        {
          title: `returns.instructions_${prefix}2_title`,
          body: `returns.instructions_${prefix}2`,
        },
        {
          title: {
            path: `returns.instructions_${prefix}3_title`,
            args: this.servicePointTranslation,
          },

          body: {
            path: `returns.instructions_${prefix}3`,
            args: this.servicePointTranslation,
          },

          content: {
            component: () => import('@/components/returns/ReturnsLocationPicker'),
            attributes: {
              class: 'tw-mb-2',
            },
          },
        },
      ];
    },
  },
};
</script>
