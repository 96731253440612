<template>
  <Card>
    <Heading
      v-t="'returns.download_printerless'"
      level="2" />

    <Paragraph
      v-t="{
        path: 'returns.download_printerless_info',
        args: servicePointTranslation,
      }" />

    <SmoothReflow>
      <Paragraph
        v-if="returnData.email_label"
        v-t="{
          path: 'returns.download_printerless_info_after',
          args: {
            email: returnData.email_label,
          },
        }" />

      <Paragraph
        v-else
        v-t="'returns.download_printerless_info_before'" />

      <Transition name="fade">
        <ReturnsDownloadForm
          v-if="!returnData.email_label"
          is-printerless />
      </Transition>

      <div class="tw--mx-5 tw-pt-4">
        <div class="tw-max-w-md tw-mx-auto tw-w-full">
          <CImage
            v-test="'returns-printerless-label'"
            :src="printerlessLabelSrc"
            alt="return_label"
            class="tw-h-56">
            <template #loading>
              <PrinterlessReturnBarcodeSkeleton />
            </template>
          </CImage>
        </div>
      </div>
    </SmoothReflow>
  </Card>
</template>

<script>
import CImage from '@/components/CImage';
import Card from '@/components/Card';
import Heading from '@/components/Heading';
import MyParcelApi from '@/services/MyParcelApi';
import Paragraph from '@/components/Paragraph';
import PrinterlessReturnBarcodeSkeleton from '@/components/returns/PrinterlessReturnBarcode.skeleton';
import ReturnsDownloadForm from '@/components/returns/ReturnsDownloadForm';
import { STORE_MODULE_RETURNS } from '@/store/modules/returns/constants';
import { getUrl } from '@/helpers/platform';
import { isDemo } from '@/services/functions/isDemo';
import { mapState } from 'vuex';

export default {
  name: 'ReturnsPrinterless',
  components: {
    PrinterlessReturnBarcodeSkeleton,
    CImage,
    ReturnsDownloadForm,
    Card,
    Paragraph,
    Heading,
  },

  computed: {
    ...mapState(STORE_MODULE_RETURNS, ['returnData']),

    /**
     * The url of the printerless return label png.
     *
     * @returns {string}
     */
    printerlessLabelSrc() {
      if (isDemo()) {
        return '';
      }

      return `${getUrl('api')}${MyParcelApi.urls.printerless_return_label}/${this.$route.params.hash}`;
    },

    /**
     * @returns {{service_point: string}}
     */
    servicePointTranslation() {
      return {
        service_point: this.$t(`carriers.${this.returnData.carrier.name}.service_point`),
      };
    },
  },
};
</script>
