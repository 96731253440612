<template>
  <div class="tw-animate-pulse tw-flex tw-h-56 tw-relative">
    <div class="tw-flex tw-flex-grow tw-px-6 tw-py-7">
      <div class="tw-bg-black tw-bg-opacity-10 tw-flex-grow tw-rounded" />
    </div>
    <div class="tw-absolute tw-bg-gray-200 tw-bottom-2 tw-h-4 tw-inset-x-0 tw-mx-auto tw-rounded tw-w-48" />
  </div>
</template>

<script>
export default {
  name: 'PrinterlessReturnBarcodeSkeleton',
};
</script>
