<template>
  <CButton
    :loading="loading"
    :variant="buttonVariant"
    :icon-right="buttonIcon"
    v-on="$listeners">
    <slot />
  </CButton>
</template>

<script>
import CButton from '@/components/CButton';
import { createNullPropValidator } from '@/helpers/validation/createNullPropValidator';
import { hasVariantProp } from '@/mixins/hasVariantProp';

export default {
  name: 'StatusButton',
  components: { CButton },
  mixins: [
    hasVariantProp,
  ],

  props: {
    loading: {
      default: null,
      validator: createNullPropValidator('boolean'),
    },

    success: {
      default: null,
      validator: createNullPropValidator('boolean'),
    },
  },

  computed: {
    /**
     * @returns {string}
     */
    buttonVariant() {
      if (this.success === null) {
        return this.variant;
      }

      return this.success ? 'success' : 'danger';
    },

    /**
     * @returns {string | null}
     */
    buttonIcon() {
      if (this.success === null) {
        return null;
      }

      return this.success ? 'checkmark' : 'cross';
    },
  },
};
</script>
